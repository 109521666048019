<template>
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <h1>Monitoring Cetak KTA</h1>
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <base-table
            ref="table"
            :columns="columns"
            :defaultFilter="searchParams"
            :service="'card-application'"
          >
            <template #header>
              <div class="row">
                <div class="col">
                  <input
                    v-model="searchParams.search"
                    type="text"
                    class="form form-control"
                    placeholder="Search..."
                  />
                </div>
                <div class="col">
                  <select
                    v-model="searchParams.status"
                    class="form form-control"
                    placeholder="Search..."
                  >
                    <option
                      v-for="(option, key) in statusOptions"
                      :key="key"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <div class="col-auto">
                  <button class="btn btn-primary" @click="$refs.table.reload()">
                    Filter
                  </button>
                </div>
              </div>
            </template>
            <template #action-button="{ data }">
              <div class="flex gap-4">
                <button
                  class="btn btn-secondary btn-sm"
                  @click="handleActionButton('detail', data.value)"
                >
                  Detail
                </button>
                <button
                  v-if="data.value.status == 'DISETUJUI'"
                  class="btn btn-success btn-sm"
                  @click="handleActionButton('tte', data.value)"
                >
                  TTD
                </button>
                <button
                  v-if="data.value.status == 'DIAJUKAN'"
                  class="btn btn-primary btn-sm"
                  @click="handleActionButton('approve', data.value)"
                >
                  APPROVE
                </button>
              </div>
            </template>
          </base-table>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>
<script>
import { toRaw } from "vue";
import BaseTable from "../../components/BaseTable.vue";
import ApprovalSevice from "../../services/approval.service";

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      searchParams: {
        search: "",
        status: ["TTE", "PRINTED"],
      },
      statusOptions: [
        { label: "Semua", value: ["TTE", "PRINTED"] },
        // { label: "Menunggu Approval", value: "DIAJUKAN" },
        // { label: "Menunggu TTD", value: "DISETUJUI" },
        { label: "Menunggu Cetak", value: "TTE" },
        { label: "Sudah Cetak", value: "PRINTED" },
      ],
      columns: [
        { field: "card.serial_number", title: "No. Seri" },
        {
          field: "card_type.name",
          title: "Jenis KTA",
          sort: false,
        },
        {
          field: "person.nama_lengkap",
          title: "Nama",
          sort: false,
        },
        {
          field: "person.dimension.name",
          title: "Matra",
          sort: false,
        },
        {
          field: "person.rank.name",
          title: "Pangkat/Korps",
          sort: false,
        },
        {
          field: "person.nip_nrp",
          title: "NRP/NBI/NIP",
          sort: false,
        },
        {
          field: "person.position.name",
          title: "Jabatan",
          sort: false,
        },
        {
          field: "person.unity.name",
          title: "Kesatuan",
          sort: false,
        },
        { field: "createdAt", title: "Tanggal Cetak" },
        { field: "status", title: "Status" },
        // { field: "actions", title: "Actions", sort: false, search: false },
      ],
    };
  },
  methods: {
    async approveAction(id, status = "DISETUJUI") {
      try {
        const request = await ApprovalSevice.approveSubmission({
          cardApplicationId: id,
          status,
        });
        this.$refs.table.reload();
        this.$swal.fire({
          title: "Success!",
          text: "Data berhasil disetujui.",
          icon: "success",
        });
        console.log(request);
      } catch (error) {
        console.log(error);
      }
    },
    confirmApproval(id) {
      this.$swal
        .fire({
          title: "Konfirmasi!",
          text: "Apakah anda yakin akan menyetujui pengajuan ini!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.approveAction(id);
          }
        });
    },
    handleActionButton(type, data) {
      const { id } = toRaw(data);
      switch (type) {
        case "approve":
          this.confirmApproval(id);
          break;
        case "tte":
          this.approveAction(id, "TTE");
          break;
        default:
          console.log("to detail");
          break;
      }
    },
  },
};
</script>
